import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ALink } from '../A';
import Container from '../Container';
import tw, { styled } from 'twin.macro';
import { slugify } from '../../helpers';
import { motion, AnimatePresence } from 'framer-motion';
import { useIndustryContext } from '../../context/industry'

const useIndustryMenu = () => {
    const data = useStaticQuery(graphql`
        {
            allWpCommercialIndustry(sort: {
                order: ASC, 
                fields: commercialIndustries___subIndustries___title
            }) {
                edges {
                    node {
                        slug
                        title
                        commercialIndustries {
                            subIndustries {
                                title
                            }
                        }
                    }
                }
            }
        }
    `)

    return data.allWpCommercialIndustry.edges.map(({node}) => {
        const {slug, title} = node;
        const [firstSubIndustry] = node.commercialIndustries.subIndustries;

        return ({
            slug, title, firstSubIndustry
        })

    });
}

const IndustryNav = () => {
    const [{ industry }] = useIndustryContext();
    const industries = useIndustryMenu();

    return (

        <div 
            tw="w-full bg-blue-600 text-white font-normal pt-10 pb-5 z-20"
        >   
            <Container>
                <nav 
                    tw="xl:(mx-20 px-20)"
                    aria-label="Industries"
                >

                    {/* Top Billing Industries */}
                    <ul 
                        tw="flex flex-col content-center row-gap-4 md:(grid  grid-cols-3)  lg:(flex flex-row justify-between text-lg) text-xl"
                        aria-label="Industries"
                    >
                        {industries.map(({ slug, title, firstSubIndustry }) => {

                            return (
                            <li tw="text-center">
                                <ALink
                                    tw="text-white no-underline hover:(text-yellow-500)"
                                    to={`/commercial/${slug}/${slugify(firstSubIndustry.title)}`}
                                    aria-current={(industry.title === title)}
                                >
                                    {title}
                                    {/* show the current industry with the yellow bar */}
                                    <AnimatePresence>
                                        {(industry.title === title) 
                                            && (
                                            <motion.div 
                                                initial={tw`bg-white w-1/4 opacity-25 mt-2 px-3 overflow-x-hidden mx-auto h-1 `} 
                                                animate={tw`bg-yellow-700 w-1/2 opacity-100`}
                                                exit={tw`bg-white w-1/4 opacity-25`}
                                            />
                                        )}
                                    </AnimatePresence>
                                </ALink>
                            </li>
                        )})}
                    </ul>
                </nav>
            </Container>
        </div>
    )
}

export default IndustryNav