import React from 'react';
import tw from 'twin.macro';
import Img from 'gatsby-image'; 
import Container from '../Container';
import { H3, H4 } from '../Headings';


const Card = ({headline, subtext, image}) => (
    <div tw="text-center flex flex-col content-start">
        <div tw="w-40 h-40 p-12 mx-auto rounded-full bg-yellow-600 mb-5">
            <Img fluid={image?.localFile?.childImageSharp?.fluid} />
        </div>
        <H4 tw="mb-5">
            { headline }
        </H4>
        <p tw="text-lg text-gray-700">
            { subtext }
        </p>
    </div>
)

const DefaultHeadline = () => (<>
    We Make It Easy To Upgrade To&nbsp;Cook's
</>)

const DefaultSubText = () => (<>
    Cook's starts by performing an inspection to help identify areas for improvement.
</>)

const CardGroup = ({ headline, subtext, cards }) => (
    <section tw="py-20" aria-label="Upgrade to Cook's">
        <Container>
            <div tw="text-gray-700 xl:(px-20 mx-20) text-center mb-10">
                <H3 tw="text-center text-5xl">
                    { headline || <DefaultHeadline />}
                </H3>
                <p tw="text-4xl">
                    { subtext || <DefaultSubText />}
                </p>
            </div>
            {(cards !== null)}
            <div tw="md:(grid grid-cols-2 gap-8) lg:(grid-cols-4) text-gray-700">

                {(Array.isArray(cards)) && cards.map((card) => (
                    <Card {...card} />
                ))}
                
            </div>
        </Container>
    </section>
)

CardGroup.defaultProps = ({
    headline: null,
    subtext: null,
    cards: null,
})

export default CardGroup



