import React from 'react';
import tw, { styled } from 'twin.macro';
import { useIndustryContext } from '../../context/industry';
import { H3 } from '../Headings';
import { ALink } from '../A'
import Img from 'gatsby-image';

const ImgWrapper = styled.div(({ aspectRatio }) => ([
    tw` max-w-xs px-5 mb-10 lg:(mb-0) xl:(h-40) z-0`,
    // set the padding based on the aspect ratio of the image
    `padding-top: calc(50% - (50% / ${aspectRatio}));`,
]))

const PestLink = ({ title, slug, fluid }) =>  (
    <ALink to={`/learn/${slug}`} tw="text-2xl content-center flex content-center justify-center flex-col">
        <ImgWrapper aspectRatio={fluid.aspectRatio}>
            <Img fluid={fluid} />
        </ImgWrapper>
        <div tw="break-normal text-center w-full z-10">
            {title}
        </div>
    </ALink>
)

const PestNav = () => {
    const [{ industry }] = useIndustryContext();

    const pests = industry.commercialIndustries.industryRelatedPests

    if (pests === null || pests.length === 0) return null;
    return (
        <div>
            <H3 tw="text-4xl mb-5">We understand the pests common to your industry...</H3>
            <div>
                <nav aria-label="Industry Related Pest Navigation">
                    <ul
                        css={[
                            tw`grid gap-8 grid-cols-3 md:(grid-cols-5)`,
                            // (pests.length > 0)
                            //     && `grid-template-columns: repeat(${pests.length}, 1fr)`
                        ]}
                    >
                        {pests.map(({title, slug, featuredImage }) => (
                            <li>
                                <PestLink 
                                    title={title} 
                                    slug={slug} 
                                    fluid={featuredImage.node.localFile.childImageSharp.fluid} 
                                />
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
       
    )
}

export default PestNav