import React from 'react';
import tw from 'twin.macro';
import { slugify } from '../../helpers';

import { ALink } from '../A'
import { useIndustryContext } from '../../context/industry'

const SubIndustryNav = () => {
    const [{ industry, subIndustry }] = useIndustryContext();
    const { subIndustries } = industry.commercialIndustries;

    return (
        <nav aria-label="Sub Industries">
            <ul tw="flex flex-row flex-wrap gap-4 lg:(mr-3 flex-col)">
                {subIndustries.map(({ title, }) => (
                    <li 
                        css={[
                            tw`mb-5 p-1 text-center lg:(text-left)`,
                            (subIndustry.title === title) 
                                && tw`border-blue-200 border-2 rounded no-underline px-3`
                        ]}
                    >
                        <ALink to={`/commercial/${industry.slug}/${slugify(title)}`}>
                            {title}
                        </ALink>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default SubIndustryNav;