import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { H1, H2, H3, H4, } from '../components/Headings'
import Img from 'gatsby-image';
import Container from '../components/Container'
import Testimonials from '../components/Testimonials'
import { ButtonLink } from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight } from '@fortawesome/free-solid-svg-icons'
import IndustryProvider from '../context/industry'
import IndustryNav from '../components/Industry/IndustryNav';
import SubIndustryNav from '../components/Industry/SubIndustryNav';
import PestNav from '../components/Industry/PestNav';
import CardGroup from '../components/Industry/CardGroup'
// import contentParser from 'gatsby-wpgraphql-inline-images'


const PageTemplate = ({ data, pageContext }) => {

    const currentIndustry = data.currentIndustry.edges[0].node;

    // get the current information by the title.
    const [ selectedSubIndustry ] = currentIndustry.commercialIndustries.subIndustries
        .filter(({title}, index) => (pageContext.subIndustryTitle !== null) 
            ? (title === pageContext.subIndustryTitle)
            : (index === 0)
        );

    const { 
        hero,
        subhero,
        cardGroup,
        footerCta,
     } = currentIndustry.commercialContent

    return (
        <>
            <SEO />
            <Layout headerType="commercial">
                {/* Hero */}
                <section tw="relative">
                    <div tw="overflow-hidden h-full w-full absolute z-0">
                        {(hero?.background?.localFile) && (
                            <Img 
                                fluid={hero.background.localFile.childImageSharp.fluid}
                                style={{ position: 'absolute' }} 
                                css={[
                                    tw`min-w-full min-h-full max-w-full max-h-full transform -translate-x-1/2 -translate-y-1/2 opacity-75`, 
                                    'z-index: -1; top:50%;left:50%;'
                                ]}
                            />
                        )}

                        <div 
                            css={[
                                tw`absolute w-full h-full z-10 opacity-75`,
                                `background-image: linear-gradient(145deg, #ffffff 41%, rgba(255, 255, 255, 0.52) 55%, rgba(128, 128, 128, 0) 83%);`
                            ]}
                        />
                    </div>
                    <Container tw="relative py-20 z-20">
                        <div tw="text-gray-700 lg:(w-2/3)">
                            <H1 tw="text-5xl mb-5">
                                { hero.headline || (
                                    <>
                                        The Experts in Commercial Pest&nbsp;Control
                                    </>
                                )}
                            </H1>
                            <p tw="text-xl leading-10 mb-5 lg:(w-2/3)">
                                { hero.subtext 
                                    || "Cook's has been controlling pest problems at large commercial sites for more than 90 years.  Whether you're in healthcare, manufacturing or another commericla field, we know how to prevent pest problems in your industry."}
                            </p>
                            <ButtonLink tw="text-2xl" to="/commercial-quote">
                                <span tw="mr-5">
                                    { hero.ctaButtonText 
                                        || "Get a Bid"}
                                </span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </ButtonLink>
                        </div>

                    </Container>
                    {(subhero !== null) && (
                        <div tw="relative w-full bg-white bg-opacity-75 z-30 py-5">
                            <Container>
                                <H2 tw="text-center text-5xl">
                                    {subhero.headline || (
                                        <>
                                            We know your industry
                                        </>
                                    )}
                                </H2>
                                <p tw="text-lg text-center ">
                                    {subhero.subtext || (
                                        <> 
                                            Select from the commercial category below to get details on our industry-specific pest control programs
                                        </>
                                    )}
                                </p>
                            </Container>
                        </div>
                    )}
                </section>

                {/* Industries Section */}
                <IndustryProvider 
                    industry={currentIndustry} 
                    subIndustry={selectedSubIndustry} 
                >
                    <section>
                        <IndustryNav />
                        <Container tw="my-10 md:(grid gap-8 grid-cols-1) lg:(grid-cols-6 gap-0)">
                            <SubIndustryNav />
                            
                            <div tw="col-span-4 text-gray-700 lg:(border-l-2 border-gray-400 pl-10)">
                                <article tw="mb-10">
                                    <H2 tw="text-5xl mb-5">{selectedSubIndustry.title}</H2>
                                    <div 
                                        tw="leading-relaxed text-lg text-gray-700" 
                                        dangerouslySetInnerHTML={{ __html :selectedSubIndustry.description}}
                                    />
                                </article>

                                <PestNav />
                            </div>
                        </Container>
                    </section>
                </IndustryProvider>

                {/* Card Group */}
                <CardGroup {...cardGroup} />

                {/* Testimonials */}
                <section>
                    <Container>
                        <Testimonials.Carousel/>
                    </Container>
                </section>

                {/* Footer CTA */}
                <section>
                    <Container tw="text-center content-center pt-10 mt-10">
                        <H2 tw="text-5xl">{ footerCta.headline || "Request A Bid"}</H2>
                        <p tw="text-lg leading-relaxed text-gray-700 lg:(px-10 mx-10) xl:(px-24 mx-24)">
                            { footerCta.subtext || "To protect your property and everything inside, contact us below for a complimentary inspection."}
                        </p>
                        <ButtonLink tw="mt-10 text-2xl" to="/commercial-quote">
                            <span tw="mr-3">
                                {footerCta.ctaButtonText || "Get a Bid"}
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </ButtonLink>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    currentIndustry : allWpCommercialIndustry (filter: { id : { eq: $id } })  {
        edges {
            node {
                title
                slug
                commercialIndustries {
                    subIndustries {
                        title
                        description
                    }
                    industryRelatedPests { 
                        ... on WpPest {
                            title 
                            slug
                            featuredImage {
                                node {
                                    localFile {
                                        childImageSharp {
                                            fluid (maxWidth: 300) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                commercialContent {
                    hero {
                        headline
                        subtext
                        ctaButtonText
                        background {
                            localFile {
                                childImageSharp {
                                    fluid (maxWidth: 1920) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }

                    subhero {
                        headline
                        subtext
                    }

                    cardGroup {
                        headline
                        subtext
                        cards {
                        image {
                            localFile {
                                childImageSharp {
                                    fluid (maxWidth: 300) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        headline
                        subtext
                        }
                    }
                    footerCta {
                        headline
                        subtext
                        ctaButtonText
                    }
                }
            }
        }
    }
  }
`