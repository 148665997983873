import React, {createContext, useContext, useReducer, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import industryReducer from './industry.reducer';


const IndustryContext = createContext(null);

const IndustryProvider = ({ children, pests, industry, subIndustry }) => {

    const [industryState, dispatchIndustry] = useReducer(industryReducer, {
        industry, subIndustry
    });

    return (
        <IndustryContext.Provider value={[ industryState, dispatchIndustry ]}>
            { children }
        </IndustryContext.Provider>
    )
}

IndustryProvider.propTypes = ({
    children : PropTypes.node.isRequired,
})

const useIndustryContext = ()  => useContext(IndustryContext);

export {
    IndustryProvider as default, 
    useIndustryContext, 
    IndustryContext
}